import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="first-container">
      <div className="first-main-content">
        <div className="tm-text-white first-page-header-container">
          <h1 className="tm-page-header">Stellar Wallet</h1>
        </div>
        <div className="img-rect-container">
          <div className="first-intro-img">
            <div className="image-container">
              <img alt="BITCOIN" src="assets/bitcoin.png" />
            </div>
          </div>
        </div>

        <section className="tm-section">
          <div className="parent-table">
            <div className="login_container">
              <Link to="/login">
                <button className="login-btn">Login</button>
              </Link>
            </div>
            <div className="register_container">
              <Link to="/register">
                <button className="register-btn">Register</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
