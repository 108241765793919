import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  isConnected,
  requestAccess,
  getAddress,
  signTransaction,
} from "@stellar/freighter-api";
import {
  Server,
  TransactionBuilder,
  Networks,
  Operation,
  Asset,
  Memo,
} from "stellar-sdk";

export default function TokenSend() {
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [balanceUsd, setBalanceUsd] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const isAppConnected = await isConnected();

        if ("isConnected" in isAppConnected && isAppConnected.isConnected) {
          console.log("User has Freighter!");
        }

        const retrievePublicKey = async () => {
          const accessObj = await requestAccess();

          if (accessObj.error) {
            return accessObj.error;
          } else {
            return accessObj.address;
          }
        };

        const sourcePublicKey = await retrievePublicKey();

        // console.log(result);

        // const sourcePublicKey = await getAddress();
        console.log(sourcePublicKey);

        const server = new Server("https://horizon.stellar.org");

        // Load the source account from Stellar
        const sourceAccount = await server.loadAccount(sourcePublicKey);

        const tokenBalance = sourceAccount.balances[0].balance;

        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=usd"
        );
        const data = await response.json();
        const xlmPrice = data.stellar.usd;

        const totalBalanceUsd = tokenBalance * xlmPrice;
        setBalanceUsd(totalBalanceUsd);
      } catch (err) {
        console.error("Error fetching balance:", err);
        // setError(err.message); // Set error message if fetching fails
      }
    };
    fetchBalance();
  }, []);
  // Function to handle sending payment via Freighter
  const sendPayment = async () => {
    const sourcePublicKey = await getAddress();

    const server = new Server("https://horizon.stellar.org");

    server
      .payments()
      .forAccount(sourcePublicKey.address)
      .cursor("now") // Start listening from the current point forward
      .stream({
        onmessage: (payment) => {
          if (payment.type === "payment") {
            console.log("Payment received:", payment);
            console.log(
              `Amount: ${payment.amount} ${payment.asset_code || "XLM"}`
            );
            console.log(`From: ${payment.from}`);
          }
        },
        onerror: (error) => {
          console.error("Error in payment stream:", error);
        },
      });

    // Load the source account from Stellar
    const sourceAccount = await server.loadAccount(sourcePublicKey.address);

    const tokenBalance = sourceAccount.balances[0].balance;

    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=usd"
    );
    const data = await response.json();
    const xlmPrice = data.stellar.usd;

    const totalBalanceUsd = tokenBalance * xlmPrice;
    setBalanceUsd(totalBalanceUsd);
    console.log(`Balance in USD: $${totalBalanceUsd}`);

    // Build the payment transaction
    try {
      const transaction = new TransactionBuilder(sourceAccount, {
        fee: await server.fetchBaseFee(),
        networkPassphrase: Networks.PUBLIC,
      })
        .addOperation(
          Operation.payment({
            destination: recipient,
            asset: Asset.native(), // Sending XLM
            amount,
          })
        )
        .addMemo(Memo.text("Test Transaction"))
        .setTimeout(30)
        .build();

      // Sign the transaction using Freighter
      const signedTransactionXDR = await signTransaction(transaction.toXDR(), {
        networkPassphrase: Networks.PUBLIC,
      });

      // Convert the signed transaction XDR back to a Transaction object
      const finalTransaction = TransactionBuilder.fromXDR(
        signedTransactionXDR.signedTxXdr,
        Networks.PUBLIC
      );
      // Submit the transaction to the Stellar network
      const result = await server.submitTransaction(finalTransaction);
      setStatus("Transaction successful!", result);
      alert("Transaction successful!", status);
    } catch (error) {
      alert("Transaction Failed!", error);
    }
  };

  return (
    <div className="send-container">
      <div className="send-main-content">
        <div className="tm-text-white send-page-header-container">
          <i className="fa fa-viacoin"></i>
          <h1 className="tm-page-header">Stellar Wallet</h1>
        </div>
        <div className="send-intro-img">
          <div className="image-container">
            <img alt="BITCOIN" src="assets/bitcoin.png" />
            <p className="text-color">Balance in USD: ${balanceUsd}</p>
          </div>
        </div>
        <section className="send-section">
          <div className="parent-table">
            <input
              className="recipient-address"
              placeholder="Enter recipient's address"
              type="text"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
            />
            <input
              className="xlm-amount"
              placeholder="Enter XLM amount"
              step="0.01"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <div className="send-btn-container">
              <button className="send-real-btn" onClick={sendPayment}>
                Send
              </button>
              <Link to="/dashboard">
                <button className="send-return-btn">Return</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
