import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isConnected, requestAccess, getAddress } from "@stellar/freighter-api";
import { Server } from "stellar-sdk";

export default function TokenDeposit() {
  const [balanceUsd, setBalanceUsd] = useState(0);
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const isAppConnected = await isConnected();

        if ("isConnected" in isAppConnected && isAppConnected.isConnected) {
          console.log("User has Freighter!");
        }

        const retrievePublicKey = async () => {
          const accessObj = await requestAccess();

          if (accessObj.error) {
            return accessObj.error;
          } else {
            return accessObj.address;
          }
        };

        const sourcePublicKey = await retrievePublicKey();

        // console.log(result);

        // const sourcePublicKey = await getAddress();
        console.log(sourcePublicKey);

        const server = new Server("https://horizon.stellar.org");

        // Load the source account from Stellar
        const sourceAccount = await server.loadAccount(sourcePublicKey);

        const tokenBalance = sourceAccount.balances[0].balance;

        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=usd"
        );
        const data = await response.json();
        const xlmPrice = data.stellar.usd;

        const totalBalanceUsd = tokenBalance * xlmPrice;
        setBalanceUsd(totalBalanceUsd);
      } catch (err) {
        console.error("Error fetching balance:", err);
        // setError(err.message); // Set error message if fetching fails
      }
    };
    fetchBalance();
  }, []);

  return (
    <div className="deposit-container">
      <div className="deposit-main-content">
        <div className="tm-text-white deposit-page-header-container">
          <i className="fa fa-viacoin"></i>
          <h1 className="tm-page-header">Stellar Wallet</h1>
        </div>
        <div className="deposit-intro-img">
          <div className="image-container">
            <img alt="BITCOIN" src="assets/bitcoin.png" />
            <p className="dps-tm-text-white">Balance in USD: ${balanceUsd}</p>
          </div>
        </div>
        <section className="deposit-section">
          <div className="parent-table">
            <button className="deposit-real-btn">Deposit</button>
            <Link to="/dashboard">
              <button className="deposit-return-btn">Return</button>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}
