import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isConnected, getAddress, requestAccess } from "@stellar/freighter-api";
import { Server } from "stellar-sdk";

export default function Dashboard() {
  const [balanceUsd, setBalanceUsd] = useState(0);
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const isAppConnected = await isConnected();

        if ("isConnected" in isAppConnected && isAppConnected.isConnected) {
          console.log("User has Freighter!");
        }

        const retrievePublicKey = async () => {
          const accessObj = await requestAccess();

          if (accessObj.error) {
            return accessObj.error;
          } else {
            return accessObj.address;
          }
        };

        const sourcePublicKey = await retrievePublicKey();

        // console.log(result);

        // const sourcePublicKey = await getAddress();
        console.log(sourcePublicKey);

        const server = new Server("https://horizon.stellar.org");

        // Load the source account from Stellar
        const sourceAccount = await server.loadAccount(sourcePublicKey);

        const tokenBalance = sourceAccount.balances[0].balance;

        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=usd"
        );
        const data = await response.json();
        const xlmPrice = data.stellar.usd;

        const totalBalanceUsd = tokenBalance * xlmPrice;
        setBalanceUsd(totalBalanceUsd);
      } catch (err) {
        console.error("Error fetching balance:", err);
        // setError(err.message); // Set error message if fetching fails
      }
    };
    fetchBalance();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-main-content">
        <div className="tm-text-white dashboard-page-header-container">
          <i className="fa fa-viacoin"></i>
          <h1 className="tm-page-header">Stellar Wallet</h1>
        </div>
        <div className="dashboard-intro-img">
          <div className="balance-container">
            <p className="text-color">Balance in USD: ${balanceUsd}</p>
          </div>
          <div className="dashboard-button-row">
            <div className="dps-button-container">
              <Link to="/deposit">
                <button className="circular-button">+</button>
              </Link>
              <div className="button-label">Deposit</div>
            </div>

            <div className="rcv-button-container">
              <button className="circular-button">-</button>
              <div className="button-label">Receive</div>
            </div>

            <div className="snd-button-container">
              <Link to="/send">
                <button className="circular-button">✓</button>
              </Link>
              <div className="button-label">Send</div>
            </div>
          </div>
        </div>
        {/* <section className="tm-section">
          <h2 className="tm-section-header">Transaction History</h2>
          <div className="parent-table">
            <table className="table table-dark table-hover transaction-table">
              <tbody>
                <tr className="tm-tr-header">
                  <th className="tm-text-left">Name</th>
                  <th>Direction</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td className="tm-text-left">Americano</td>
                  <td>RCV</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td className="tm-text-left">Cuppucino</td>
                  <td>SND</td>
                  <td>$18</td>
                </tr>
                <tr>
                  <td className="tm-text-left">Fresh Latte</td>
                  <td>RCV</td>
                  <td>$18</td>
                </tr>
                <tr>
                  <td className="tm-text-left">Mocha</td>
                  <td>DPS</td>
                  <td>$18</td>
                </tr>
                <tr>
                  <td className="tm-text-left">Espresso</td>
                  <td>DPS</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td className="tm-text-left">Black Coffee</td>
                  <td>RCV</td>
                  <td>$16</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section> */}
      </div>
    </div>
  );
}
