import React from "react";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div className="login-container">
      <div className="login-main-content">
        <div className="tm-text-white login-page-header-container">
          <i className="fa fa-viacoin"></i>
          <h1 className="tm-page-header">Stellar Wallet</h1>
        </div>
        <div className="login-intro-img">
          <div className="image-container">
            <img alt="Register" src="assets/register.png" />
          </div>
        </div>

        <section className="tm-section">
          <div className="parent-table">
            <input
              className="email-container"
              placeholder="Email address input"
              type="email"
            />
            <input
              className="password-container"
              placeholder="Password input"
              type="password"
            />
            <div className="login-btn-container">
              <Link to="/dashboard">
                <button className="login-real-btn">Login</button>
              </Link>

              <Link to="/">
                <button className="login-return-btn">Return</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
