import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/user_management/Login";
import Register from "./pages/user_management/Register";
import Dashboard from "./pages/dashboard/dashboard";
import TokenDeposit from "./pages/user_action/TokenDeposit";
import TokenSend from "./pages/user_action/TokenSend";
import "./App.css";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/deposit" element={<TokenDeposit />} />
        <Route path="/send" element={<TokenSend />} />
      </Routes>
    </div>
  );
};

export default App;
